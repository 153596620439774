
<script>
import { PolarArea, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  name: 'PolarChartSkills',
  extends: PolarArea,
  mixins: [reactiveProp],
  props: ['chartData'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style scoped lang="scss">
</style>
