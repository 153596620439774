
<script>
import { Pie, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
export default {
  name: 'PieChartSkills',
  extends: Pie,
  mixins: [reactiveProp],
  props: ['chartData', 'clickCb'],
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),
  mounted () {
    let self = this
    this.renderChart(this.chartData, this.options)
    this.$refs.canvas.onclick = function (evt) {
      var activePoints = self.$data._chart.getElementsAtEvent(evt)
      if (activePoints.length !== 0) {
        self.clickCb(activePoints[0]._index)
      }
      // => activePoints is an array of points on the canvas that are at the same position as the click event.
    }
  }
}
</script>

<style scoped lang="scss">
</style>
