<template>
  <div class="what-i-do">
	<loader :is-ready="isReady" :is-disable="isDisable"></loader>
    <h2><span class="txt-highlight">What</span> I do</h2>
	<div class="charts-wrap">
		<button class="back-btn" @click="backToSkill()" v-if="isFeChartVisible || isBeChartVisible">
			<span class="icon">&#8612;</span> <span class="label">Back to skills</span>
		</button>
		<div class="call-to-action-charts" v-if="!isFeChartVisible && !isBeChartVisible">
			<p>
				<span class="txt-highlight">Click</span> on a slice of the pie to see the details <span class="icon">&#8680;</span>
			</p>
		</div>
		<div class="pie-chart-skills">
			<pie-chart-skills v-if="!isFeChartVisible && !isBeChartVisible" :chart-data="dataCollection" :click-cb="clickedSkill"></pie-chart-skills>
			<!-- front end chart -->
			<polar-chart-skills v-if="isFeChartVisible" :chart-data="dataCollectionFe"></polar-chart-skills>
			<!-- back end chart -->
			<polar-chart-skills v-if="isBeChartVisible" :chart-data="dataCollectionBe"></polar-chart-skills>
		</div>
	</div>
    <h4 class="title-with-line txt-l" v-if="!isBeChartVisible">
			<span class="txt-highlight">F</span>rontend:
		</h4>
		<br>
		<p v-if="!isBeChartVisible">
			Let's start with the fundamentals: <span class="txt-highlight">my level of confidence with HTML, CSS and Javascript is very strong</span>. When I started my carreer there wasn't any framework except jQuery, so I had to learn web development the hard way.
			<br><br>
			<span class="txt-highlight">I'm a Vue.js fan</span>. Working on web applications, <span class="txt-highlight">designing, prototyping and implementig components is the best part of my job</span>. And the more complicated is the component, the more fun I have working on it.<br>
			Vue.js is just the perfect sidekick for this type of task.<br>
			I started to use Vue.js in 2016, and I used it almost every day. I can say to have a <span class="txt-highlight">pretty solid experience on working on SPA</span>.
		</p>
		<br>
		<h4 class="title-with-line txt-l" v-if="!isFeChartVisible">
			<span class="txt-highlight">B</span>ackend:
		</h4>
		<br>
		<p v-if="!isFeChartVisible">
			<span class="txt-highlight">Laravel</span> is my to go framework for the backend. I learned it thanks to <span class="txt-highlight">OctoberCMS</span>, a very good CMS based on Laravel.<br>Lately I'm working on a project that is based on <span class="txt-highlight">Django</span>. I found it very powerfull and easy to use. Set up a <span class="txt-highlight">REST service</span> with Django is really a breeze.<br>
			In the past I worked with <span class="txt-highlight">Asp.net (C#), PHP and Node</span>.<br><br>
			As for databases I worked with <span class="txt-highlight">mySql, mongoDb, postgreSql and sqlServer</span>.<br><br>
			I have a couple of VPS that I configured from scratch with all the technologies that I need for test. It's kind of my playground and it's where I learned how to <span class="txt-highlight">configure a webserver</span> with it's virtual hosts, how to <span class="txt-highlight">deploy</span> a django application on Apache and many other <span class="txt-italic">"awesome*"</span> things.
			<br><br><br>
			<span class="txt-italic txt-small">* Could be ironic</span>
		</p>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import PieChartSkills from '@/components/PieChartSkills.vue'
import PolarChartSkills from '@/components/PolarChartSkills.vue'

export default {
  name: 'What-i-do',
  components: {
    Loader,
    PieChartSkills,
		PolarChartSkills
  },
  data () {
		return {
			isReady: false,
			isDisable: false,
			// charts
			skillsLabels: ['Backend', 'Frontend'],
			dataCollection: null,
			dataCollectionFe: null,
			dataCollectionBe: null,
			isFeChartVisible: false,
			isBeChartVisible: false
		}
	},
	beforeRouteLeave (to, from, next) {
    let self = this
		this.isDisable = false
		setTimeout(function () {
      self.isReady = false
    }, 50)
    setTimeout(function () {
      next()
    }, 650)
  },
  head: {
		title: {
			inner: 'Lorenzo Cogliati - What I do'
		},
		meta: [
			{ name: 'application-name', content: 'Cogliawho' },
			{ name: 'description', content: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js' },
			// Twitter
			{ name: 'twitter:title', content: 'Lorenzo Cogliati - What I do' },
			// with shorthand
			{ n: 'twitter:description', c: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js'},
			// Google+ / Schema.org
			{ itemprop: 'name', content: 'Lorenzo Cogliati - What I do' },
			{ itemprop: 'description', content: 'Hi! My name is Lorenzo and I’am a full stack web developer specialized in developing SPA with Vue.js' },
			{ p: 'og:image', c: 'https://cogliawho.it' + require('@/assets/coglia_who_logo.png') },
		]
	},
  methods: {
		backToSkill () {
			this.isFeChartVisible = false
			this.isBeChartVisible = false
		},
		clickedSkill (index) {
			if (this.skillsLabels[index] === 'Frontend') {
				// show front end POLAR
				this.isFeChartVisible = true
			}
			if (this.skillsLabels[index] === 'Backend') {
				// show back end POLAR
				this.isBeChartVisible = true
			}
		},
    fillData () {
			this.dataCollection = {
				labels: this.skillsLabels,
        datasets: [
          {
            label: 'Skills',
            backgroundColor: ['#FFE74C', '#35A7FF'],
            data: [15, 85]
          }
        ]
      }
      this.dataCollectionFe = {
        labels: ['UI/UX', 'Vue.js', 'Javascript', 'HTML/CSS'],
        borderAlign: 'inner',
        datasets: [
          {
            backgroundColor: ['rgba(255, 231, 76, .4)', 'rgba(255, 89, 100, .4)', 'rgba(107, 241, 120, .4)', 'rgba(53, 167, 255, .4)'],
            // borderColor: ['#FFE74C', '#FF5964', '#6BF178', '#35A7FF'],
            data: [80, 95, 90, 90]
          }
        ]
      }
      this.dataCollectionBe = {
        labels: ['Apache', 'Laravel/PHP', 'Python', 'Databases'],
        borderAlign: 'inner',
        datasets: [
          {
            backgroundColor: ['rgba(255, 231, 76, .4)', 'rgba(255, 89, 100, .4)', 'rgba(107, 241, 120, .4)', 'rgba(53, 167, 255, .4)'],
            data: [50, 80, 55, 60]
          }
        ]
      }
    }
  },
  mounted () {
    let self = this
    this.fillData()
		setTimeout(function () {
			self.isReady = true
    }, 100)
    setTimeout(function () {
			self.isDisable = true
    }, 750)
  }
}
</script>

<style lang="scss">
.what-i-do{
	height: 100%;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	overflow: auto;
	padding-right: 10px;
	h2{
		margin-bottom: 2rem;
	}
	h4{
		&.title-with-line{
			position: relative;
			font-size: 1.4rem;
			padding-bottom: 5px;
			&:after{
				content: "";
				display: block;
				height: 1px;
				width: 100%;
				position: absolute;
				bottom: 0px;
				left: 0px;
				background-color: deeppink;
			}
		}
	}
	p{
		text-align: justify;
		padding-right: 10px;
	}
	.charts-wrap{
		position: relative;
		width: 100%;
		overflow: hidden;
		margin: 25px 0px;
		padding-bottom: 25px;
		.back-btn{
			position: absolute;
			top: 0px;
			left: 0px;
			border: 0px;
			font-size: 1rem;
			cursor: pointer;
			.icon{
				font-size: 1.2rem;
			}
			&:hover{
				.label{
					text-decoration: underline;
				}
			}
		}
		.call-to-action-charts{
			position: absolute;
			top: 50%;
			left: 0px;
			width: 20%;
			transform: translate(0, -50%);
		}
	}
}
@media (max-width: 1150px)  {
	.what-i-do{
		.charts-wrap{
			.back-btn{
				font-size: .8rem;
			}
			.call-to-action-charts{
				font-size: .8rem;
			}
		}
	}
}
@media (max-width: 1023px)  {
	.what-i-do {
		.charts-wrap{
			padding-top: 25px;
			.back-btn{
				top: 0px;
			}
			.call-to-action-charts{
				position: initial;
				top: initial;
				transform: none;
				width: 100%;
				text-align: center;
				margin-bottom: 10px;
				p{
					text-align: center;
					padding-right: 0px;
					.icon{
						display: none;
					}	
				}
			}	
		}
	}
}
</style>